<template>
  <ion-content class="">
    <div class="flex items-center h-full">
      <div class="flex-1">
        <div class="ion-text-center flex justify-center mb-5">
          <router-link :to="{ name: 'news' }">
            <ion-img src="/img/logo.svg" class="logo" />
          </router-link>
        </div>

        <form @submit.prevent="submit" autocomplete="off">
          <ion-input type="text" name="username" value="" style="display: none !important;" aria-hidden />
          <div class="ion-padding" v-if="!success">
            <ShowHidePassword
              :label="$t('fillinnewpassword')"
              :value="password"
              @input="password = $event"
              :placeholder="$t('password')"
              :passwordVisible="passwordVisible"
              @showPassword="passwordVisible = $event"
              />
            <ShowHidePassword
              :label="$t('repeatnewpassword')"
              :value="confirmPassword"
              @input="confirmPassword = $event"
              :placeholder="$t('passwordconfirmation')"
              :passwordVisible="passwordVisible"
              @showPassword="passwordVisible = $event"
              />
          </div>

          <div class="ion-padding">
            <ion-button
              :disabled="loading || success"
              size="medium"
              type="submit"
              expand="full"
              color="moto"
            >
              <ion-text v-if="!loading">
                {{ $t('resetyourpassword') }}
              </ion-text>
              <ion-spinner v-else name="crescent" color="primary" />
            </ion-button>
            <ion-button
              v-if="!success"
              size="medium"
              type="button"
              expand="full"
              @click.prevent="returnToLogin"
            >
              {{ $t('backtologin') }}
            </ion-button>
            <ion-button
              v-else
              size="medium"
              type="button"
              expand="full"
              @click.prevent="returnToHome"
            >
              {{ $t('backtohome') }}
            </ion-button>
            <Error :error="error" />

            <div v-if="success">
              {{ $t('resetpasswordsuccesfull') }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { showToast } from "@/utils/helpers";
import { NEW_PASSWORD_WITH_TOKEN_ACTION } from "@/store/store-actions";
import ShowHidePassword from "@/components/inputs/ShowHidePassword.vue";

export default {
  components: {ShowHidePassword},
  data() {
    return {
      passwordVisible: false,
      password: null,
      confirmPassword: null,
      success: false,
      loading: false,
      error: null
    };
  },
  methods: {
    showToast,
    returnToHome() {
      this.$router.push({name: this.$routeNames.HOME}).catch(() => null)
    },
    returnToLogin() {
      this.$router.push({name: this.$routeNames.LOGIN}).catch(() => null)
    },
    async submit() {
      this.loading = false;
      this.success = false;
      this.error = null;

      try {
        await this.$store.dispatch(NEW_PASSWORD_WITH_TOKEN_ACTION, {
          token: this.$route.query.token,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });

        this.success = true;
        await this.showToast( this.$t('resetpasswordsuccesfull'), 'success', 6000);
      } catch(error) {
          this.error = error
      }
    },
  },
};
</script>
